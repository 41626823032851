import type { NuxtApp } from '#app'
import type { CurrentUser } from '~/types/user'

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const nuxtApp = useNuxtApp() as NuxtApp
  const consola = useConsola()

  consola.debug('[admin-audit] start')

  const sessionStore = useSessionStore()

  if (!sessionStore.user) {
    const user: CurrentUser = await nuxtApp.$kickflowUserApi.getCurrentUser()
    sessionStore.setUser(user)
  }

  if (!sessionStore.isAuditAdmin) {
    consola.debug('Not admin. render error page')
    throw createError({
      statusCode: 403,
      message: 'Forbidden',
    })
  }

  consola.debug('[admin-audit] end')
})
